@media print {
  .pageBreak {
    display: block;
    page-break-before: always;
  }
  .submitButton {
    display: none;
  }
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input,
select {
  font-family: inherit;
  font-size: 0.95rem;
}

.mainView {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.input {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-right: 20px;
  max-width: 200px;
  /*border: 1px dotted lightblue;
  background-color: rgba(0, 120, 250, 0.1);*/
}

.draw {
  /*border: 1px dotted rgba(255, 0, 0, 0.1);
  background-color: rgba(255, 0, 0, 0.05);*/
}

.input table {
  border-collapse: collapse;
}

.input input {
  width: 50px;
}

.input .checkBox {
  width: 20px;
}

.addButton {
  margin: 10px auto 10px;
}

.submitButton {
  margin: 15px 0;
  padding: 10px 20px;
}

.subTitle {
  padding: 10px 0 5px;
}

.subTitleWithCheckbox {
  padding-top: 10px;
  text-align: left;
}

.pageTitle {
  margin: 0 auto 25px;
  width: 70%;
  color: brown;
  /*border: 1px dotted rgba(204, 202, 161);
  background-color: rgba(204, 202, 161, 0.1);*/
}

.divHide {
  display: none;
}
.divShow {
  display: flex;
  flex-direction: column;
}

.soilTable {
  margin: 0 auto 15px;
  max-width: 70%;
  display: flex;
  flex-direction: column;
  /*border: 1px dotted rgba(204, 202, 161);
  background-color: rgba(204, 202, 161, 0.1);*/
}
.soilTable > button {
  margin-left: 10%;
}
.soilTable > div {
  margin-left: 5;
  text-align: left;
}
