@media print {
  .footer {
    display: none;
  }
}

.footer {
  margin: 30px;
}
.footer a {
  text-decoration: none;
  color: maroon;
}
.footer a:hover {
  color: brown;
}
