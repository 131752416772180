.menuItem {
  width: 200px;
  height: 240px;
  padding: 10px;
  margin: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 2px 4px rgba(255, 0, 0, 0.2);
}
.imageBox {
  width: 90%;
}
.imageBox img {
  width: 100%;
}
.title {
  padding-bottom: 10px;
}
.title a {
  text-decoration: none;
}
