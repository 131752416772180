.report {
  margin: 0px auto 50px;
  max-width: 70%;
  text-align: left;
  /*border: 1px dotted rgba(0, 250, 0, 0.5);
  background-color: rgba(0, 250, 0, 0.1);*/
}
.reportAttention {
  margin: 5px 20px 5px 5px;
  padding: 5px 10px;
  border-left: 5px solid orange;
  background-color: rgba(255, 165, 0, 0.1);
}
.reportTitle {
  text-align: center;
}
