input,
select,
th,
button,
label {
  font-family: inherit;
  font-weight: inherit;
  font-size: 0.95rem;
}
.table input {
  width: 40px;
}
.table select {
  max-width: 80px;
}
.table th {
  background-color: rgba(245, 185, 185, 0.2);
}
.inputError {
  background-color: rgba(255, 0, 0, 0.2);
}
label {
  vertical-align: top;
}
