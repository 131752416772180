.dataTable td:first-child {
  width: 30%;
  text-align: right;
}

.dataTable td:last-child {
  width: 30%;
  text-align: left;
}

.inputError {
  background-color: rgba(255, 0, 0, 0.2);
}
