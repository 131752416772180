.header {
  display: flex;
  justify-content: center;
}
.title {
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  font-size: 2.5em;
  color: maroon;
  cursor: pointer;
}
